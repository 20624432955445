import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
class AuthService {
  login(login, password) {
    // console.log(API_URL);
    return axios.post(`${API_URL}/login/admin`, { login, password }, { headers: { Accept: 'application/json' } }).then((response) => {
      // console.log(response);
      if (response.data.data.token) {
        localStorage.setItem('user', JSON.stringify(response.data.data));
      }
      return response.data.data;
    });
  }
  logout() {
    localStorage.removeItem('user');
  }
  register(username, email, password) {
    return axios.post(API_URL + 'register', {
      username,
      email,
      password
    });
  }
}
export default new AuthService();
