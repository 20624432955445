import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';
import messageReducer from './messageReducer';

const reducers = combineReducers({
  form: formReducer,
  authReducer,
  messageReducer
});

export default reducers;
