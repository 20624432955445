import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import routes, { renderRoutes } from './routes';

const App = () => {
  return (
    <React.Fragment>
      <Router>{renderRoutes(routes)}</Router>
    </React.Fragment>
  );
};

export default App;
