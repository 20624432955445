import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
// import { createStore, applyMiddleware } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import thunk from 'redux-thunk';
// import rootReducer from "./reducers";

import reducers from './reducers';
// const middleware = [thunk];
const store = configureStore({
  reducer: reducers,
  devTools: true
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
