import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import { BASE_URL } from './config/constant';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/auth/signin',
    component: lazy(() => import('./views/auth/signin/SignIn1'))
  },
  {
    exact: true,
    path: '/auth/signup',
    component: lazy(() => import('./views/auth/signup/SignUp1'))
  },
  {
    path: '*',
    layout: AdminLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('./views/dashboard/DashDefault'))
      },
      {
        exact: true,
        path: '/agents',
        component: lazy(() => import('./views/agents/Agents'))
      },
      {
        exact: true,
        path: '/agents/create',
        component: lazy(() => import('./views/agents/AgentCreate'))
      },
      {
        exact: true,
        path: '/agents/show/:id',
        component: lazy(() => import('./views/agents/AgentsShow'))
      },
      {
        exact: true,
        path: '/agents/edit/:id',
        component: lazy(() => import('./views/agents/AgentsEdit'))
      },
      {
        exact: true,
        path: '/users',
        component: lazy(() => import('./views/users/Users'))
      },
      {
        exact: true,
        path: '/users/show/:id',
        component: lazy(() => import('./views/users/User'))
      },
      {
        exact: true,
        path: '/category',
        component: lazy(() => import('./views/category/Category'))
      },
      {
        exact: true,
        path: '/category/show/:id',
        component: lazy(() => import('./views/category/CategoryShow'))
      },
      {
        exact: true,
        path: '/category/edit/:id',
        component: lazy(() => import('./views/category/CategoryEdit'))
      },
      {
        exact: true,
        path: '/services',
        component: lazy(() => import('./views/services/Services'))
      },
      {
        exact: true,
        path: '/services/show/:id',
        component: lazy(() => import('./views/services/ServicesShow'))
      },
      {
        exact: true,
        path: '/services/edit/:id',
        component: lazy(() => import('./views/services/ServicesEdit'))
      },
      {
        exact: true,
        path: '/requests',
        component: lazy(() => import('./views/requests/Requests'))
      },
      {
        exact: true,
        path: '/requests/show/:id',
        component: lazy(() => import('./views/requests/RequestsShow'))
      },
      {
        exact: true,
        path: '/cars/brand',
        component: lazy(() => import('./views/cars/brand/Brand'))
      },
      {
        exact: true,
        path: '/cars/brand/show/:id',
        component: lazy(() => import('./views/cars/brand/BrandShow'))
      },
      {
        exact: true,
        path: '/cars/brand/create',
        component: lazy(() => import('./views/cars/brand/BrandCreate'))
      },
      {
        exact: true,
        path: '/cars/model',
        component: lazy(() => import('./views/cars/model/Model'))
      },
      {
        exact: true,
        path: '/cars/model/create',
        component: lazy(() => import('./views/cars/model/ModelCreate'))
      },
      {
        exact: true,
        path: '/cars/model/show/:id',
        component: lazy(() => import('./views/cars/model/ModelShow'))
      },
      {
        exact: true,
        path: '/cars/varient',
        component: lazy(() => import('./views/cars/varient/Varient'))
      },
      {
        exact: true,
        path: '/cars/varient/create',
        component: lazy(() => import('./views/cars/varient/VarientCreate'))
      },
      {
        exact: true,
        path: '/cars/varient/show/:id',
        component: lazy(() => import('./views/cars/varient/VarientShow'))
      },
      {
        exact: true,
        path: '/cars/sell',
        component: lazy(() => import('./views/cars/sell/Sell'))
      },
      {
        exact: true,
        path: '/cars/sell/show/:id',
        component: lazy(() => import('./views/cars/sell/SellShow'))
      },
      {
        exact: true,
        path: '/cars/sell/create',
        component: lazy(() => import('./views/cars/sell/SellCreate'))
      },
      {
        exact: true,
        path: '/banner',
        component: lazy(() => import('./views/banner/Banner'))
      },
      {
        exact: true,
        path: '/banner/create',
        component: lazy(() => import('./views/banner/BannerCreate'))
      },
      {
        exact: true,
        path: '/settings',
        component: lazy(() => import('./views/settings/Settings'))
      },
      {
        exact: true,
        path: '/settings/edit',
        component: lazy(() => import('./views/settings/SettingsEdit'))
      },
      {
        exact: true,
        path: '/safety',
        component: lazy(() => import('./views/safety/Safety'))
      },
      {
        exact: true,
        path: '/safety/create',
        component: lazy(() => import('./views/safety/SafetyCreate'))
      },
      {
        exact: true,
        path: '/safety/edit/:id',
        component: lazy(() => import('./views/safety/SafetyEdit'))
      },
      // {
      //   exact: true,
      //   path: '/basic/button',
      //   component: lazy(() => import('./views/ui-elements/basic/BasicButton'))
      // },
      // {
      //   exact: true,
      //   path: '/basic/badges',
      //   component: lazy(() => import('./views/ui-elements/basic/BasicBadges'))
      // },
      // {
      //   exact: true,
      //   path: '/basic/breadcrumb',
      //   component: lazy(() => import('./views/ui-elements/basic/BasicBreadcrumb'))
      // },
      // {
      //   exact: true,
      //   path: '/basic/pagination',
      //   component: lazy(() => import('./views/ui-elements/basic/BasicPagination'))
      // },
      // {
      //   exact: true,
      //   path: '/basic/collapse',
      //   component: lazy(() => import('./views/ui-elements/basic/BasicCollapse'))
      // },
      // {
      //   exact: true,
      //   path: '/basic/tabs-pills',
      //   component: lazy(() => import('./views/ui-elements/basic/BasicTabsPills'))
      // },
      // {
      //   exact: true,
      //   path: '/basic/typography',
      //   component: lazy(() => import('./views/ui-elements/basic/BasicTypography'))
      // },
      // {
      //   exact: true,
      //   path: '/forms/form-basic',
      //   component: lazy(() => import('./views/forms/FormsElements'))
      // },
      // {
      //   exact: true,
      //   path: '/tables/bootstrap',
      //   component: lazy(() => import('./views/tables/BootstrapTable'))
      // },
      // {
      //   exact: true,
      //   path: '/charts/nvd3',
      //   component: lazy(() => import('./views/charts/nvd3-chart'))
      // },
      // {
      //   exact: true,
      //   path: '/maps/google-map',
      //   component: lazy(() => import('./views/maps/GoogleMaps'))
      // },
      // {
      //   exact: true,
      //   path: '/sample-page',
      //   component: lazy(() => import('./views/extra/SamplePage'))
      // },
      {
        path: '*',
        exact: true,
        component: () => <Redirect to={BASE_URL} />
      }
    ]
  }
];

export default routes;
