const menuItems = {
  items: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/dashboard',
          icon: 'feather icon-home'
        },
        {
          id: 'agents',
          title: 'Agents',
          type: 'item',
          url: '/agents',
          icon: 'feather icon-user'
        },
        {
          id: 'users',
          title: 'Users',
          type: 'item',
          url: '/users',
          icon: 'feather icon-users'
        },
        {
          id: 'category',
          title: 'Category',
          type: 'item',
          url: '/category',
          icon: 'feather icon-list'
        },
        {
          id: 'services',
          title: 'Services',
          type: 'item',
          url: '/services',
          icon: 'feather icon-sliders'
        },
        {
          id: 'requests',
          title: 'Requests',
          type: 'item',
          url: '/requests',
          icon: 'feather icon-inbox'
        },
        {
          id: 'ui-cars',
          title: 'Cars',
          type: 'collapse',
          icon: 'fa fa-car',
          children: [
            {
              id: 'brand',
              title: 'Car Brand',
              type: 'item',
              url: '/cars/brand'
            },
            {
              id: 'model',
              title: 'Car Model',
              type: 'item',
              url: '/cars/model'
            },
            {
              id: 'varient',
              title: 'Car Varient',
              type: 'item',
              url: '/cars/varient'
            }
          ]
        },
        {
          id: 'sell',
          title: 'Pre Owned Cars',
          type: 'item',
          url: '/cars/sell',
          icon: 'feather icon-briefcase'
        },
        {
          id: 'banner',
          title: 'Banners',
          type: 'item',
          url: '/banner',
          icon: 'feather icon-inbox'
        },
        {
          id: 'safety',
          title: 'Safety',
          type: 'item',
          url: '/safety',
          icon: 'feather icon-shield'
        },
        {
          id: 'setting',
          title: 'Settings',
          type: 'item',
          url: '/settings',
          icon: 'feather icon-settings'
        }
      ]
    }
    // {
    //   id: 'ui-element',
    //   title: 'UI ELEMENT',
    //   type: 'group',
    //   icon: 'icon-ui',
    //   children: [
    //     {
    //       id: 'basic',
    //       title: 'Component',
    //       type: 'collapse',
    //       icon: 'feather icon-box',
    //       children: [
    //         {
    //           id: 'button',
    //           title: 'Button',
    //           type: 'item',
    //           url: '/basic/button'
    //         },
    //         {
    //           id: 'badges',
    //           title: 'Badges',
    //           type: 'item',
    //           url: '/basic/badges'
    //         },
    //         {
    //           id: 'breadcrumb',
    //           title: 'Breadcrumb',
    //           type: 'item',
    //           url: '/basic/breadcrumb'
    //         },
    //         {
    //           id: 'pagination',
    //           title: 'Pagination',
    //           type: 'item',
    //           url: '/basic/pagination'
    //         },
    //         {
    //           id: 'collapse',
    //           title: 'Collapse',
    //           type: 'item',
    //           url: '/basic/collapse'
    //         },
    //         {
    //           id: 'tabs-pills',
    //           title: 'Tabs & Pills',
    //           type: 'item',
    //           url: '/basic/tabs-pills'
    //         },
    //         {
    //           id: 'typography',
    //           title: 'Typography',
    //           type: 'item',
    //           url: '/basic/typography'
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   id: 'forms-tables',
    //   title: 'Forms & Tables',
    //   type: 'group',
    //   icon: 'icon-group',
    //   children: [
    //     {
    //       id: 'forms',
    //       title: 'Form Elements',
    //       type: 'item',
    //       url: '/forms/form-basic',
    //       icon: 'feather icon-file-text'
    //     },
    //     {
    //       id: 'tables',
    //       title: 'Table',
    //       type: 'item',
    //       url: '/tables/bootstrap',
    //       icon: 'feather icon-server'
    //     }
    //   ]
    // },
    // {
    //   id: 'chart-maps',
    //   title: 'Chart & Maps',
    //   type: 'group',
    //   icon: 'icon-charts',
    //   children: [
    //     {
    //       id: 'charts',
    //       title: 'Charts',
    //       type: 'item',
    //       url: '/charts/nvd3',
    //       icon: 'feather icon-pie-chart'
    //     },
    //     {
    //       id: 'maps',
    //       title: 'Map',
    //       type: 'item',
    //       url: '/maps/google-map',
    //       icon: 'feather icon-map'
    //     }
    //   ]
    // },
    // {
    //   id: 'pages',
    //   title: 'Pages',
    //   type: 'group',
    //   icon: 'icon-pages',
    //   children: [
    //     {
    //       id: 'auth',
    //       title: 'Authentication',
    //       type: 'collapse',
    //       icon: 'feather icon-lock',
    //       badge: {
    //         title: 'New',
    //         type: 'label-danger'
    //       },
    //       children: [
    //         {
    //           id: 'signup-1',
    //           title: 'Sign up',
    //           type: 'item',
    //           url: '/auth/signup-1',
    //           target: true,
    //           breadcrumbs: false
    //         },

    //         {
    //           id: 'signin-1',
    //           title: 'Sign in',
    //           type: 'item',
    //           url: '/auth/signin-1',
    //           target: true,
    //           breadcrumbs: false
    //         }
    //       ]
    //     },
    //     {
    //       id: 'sample-page',
    //       title: 'Sample Page',
    //       type: 'item',
    //       url: '/sample-page',
    //       classes: 'nav-item',
    //       icon: 'feather icon-sidebar'
    //     },
    //     {
    //       id: 'documentation',
    //       title: 'Documentation',
    //       type: 'item',
    //       icon: 'feather icon-help-circle',
    //       classes: 'nav-item',
    //       url: 'https://codedthemes.com/item/datta-able-react-free-admin-template/#',
    //       target: true,
    //       external: true
    //     },
    //     {
    //       id: 'menu-level',
    //       title: 'Menu Levels',
    //       type: 'collapse',
    //       icon: 'feather icon-menu',
    //       children: [
    //         {
    //           id: 'menu-level-1.1',
    //           title: 'Menu Level 1.1',
    //           type: 'item',
    //           url: '#!'
    //         },
    //         {
    //           id: 'menu-level-1.2',
    //           title: 'Menu Level 2.2',
    //           type: 'collapse',
    //           children: [
    //             {
    //               id: 'menu-level-2.1',
    //               title: 'Menu Level 2.1',
    //               type: 'item',
    //               url: '#'
    //             },
    //             {
    //               id: 'menu-level-2.2',
    //               title: 'Menu Level 2.2',
    //               type: 'collapse',
    //               children: [
    //                 {
    //                   id: 'menu-level-3.1',
    //                   title: 'Menu Level 3.1',
    //                   type: 'item',
    //                   url: '#'
    //                 },
    //                 {
    //                   id: 'menu-level-3.2',
    //                   title: 'Menu Level 3.2',
    //                   type: 'item',
    //                   url: '#'
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ]
    //     },
    //     {
    //       id: 'disabled-menu',
    //       title: 'Disabled Menu',
    //       type: 'item',
    //       url: '#',
    //       classes: 'nav-item disabled',
    //       icon: 'feather icon-power'
    //     }
    //   ]
    // }
  ]
};

export default menuItems;
